<template>
	<div class="grid">

	</div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
